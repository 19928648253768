<template>
    <div class="main" v-loading="loading">
        <div class="left">
            <div class="kefan">
                <!-- 搜索 -->
                <div class="search">
                    <el-input placeholder="请输入内容" v-model="value">
                        <i slot="prefix" class="el-input__icon el-icon-search"></i>
                    </el-input>
                    <el-button type="primary" @click="searchtitle" plain>搜索</el-button>
                    <el-button type="success" plain @click="dialogVisible = true">新增</el-button>
                </div>
                <!-- card -->
                <div class="cardview">
                    <div class="card" v-for="item in datalist" :key="item.id" v-highlight>
                        <div class="chead">
                            <i class="el-icon-s-promotion"></i>
                            <p class="title">{{item.title }}</p>
                        </div>

                        <pre>
                                 <code class="sql">{{item.content}}</code>
                                 <div class="paste" @click="copyText(item.content)">
                                    <i class="el-icon-copy-document"></i>
                                 </div>
                            </pre>


                        <div class="carddown">
                            <div class="tagbox">
                                <el-tag v-for="it in item.tag" :key="it" size="mini">#{{it}}</el-tag>
                            </div>
                            <div class="name">作者：{{item.username }}</div>
                        </div>

                        <div class="dash"></div>
                    </div>
                </div>
                <el-pagination
                background
                @current-change="handleCurrentChange"
                :current-page="page"
                layout="prev, pager, next"
                :total="num">
                </el-pagination>
            </div>
        </div>
        <div class="right">
            <div class="kefan top">
                <div class="search">
                    <el-input placeholder="请输入标签关键字" v-model="tagvalue">
                        <i slot="prefix" class="el-input__icon el-icon-search"></i>
                    </el-input>
                    <el-button type="primary" @click="searchtag" plain>搜索</el-button>
                </div>
                <div class="tagbox tagdown">
                    <el-tag id="tag" v-for="item in taglist" :key="item.id" :type="tag == item.text ? 'success':'info' " @click="selecttag(item.text)" size="mini">#{{item.text}}</el-tag>
                </div>
            </div>
        </div>
        <el-dialog title="新增sql语句" v-loading="loading" :visible.sync="dialogVisible" width="50%">
            <div class="form">
                <el-form :model="ruleForm" :rules="rules"  label-width="70px"
                    class="demo-ruleForm">
                    <el-form-item label="标题" prop="title">
                        <el-input  v-model="ruleForm.title"></el-input>
                    </el-form-item>
                    <el-form-item label="sql语句" prop="sql">
                        <el-input type="textarea" v-model="ruleForm.sql" maxlength="-1" ></el-input>
                    </el-form-item>
                    <el-form-item label="标签">
                        <el-tag
                        :key="tag"
                        v-for="tag in dynamicTags"
                        closable
                        :disable-transitions="false"
                        @close="handleClose(tag)">
                        {{tag}}
                        </el-tag>
                        <el-input
                        class="input-new-tag"
                        v-if="inputVisible"
                        v-model="inputValue"
                        ref="saveTagInput"
                        size="small"
                        @keyup.enter.native="handleInputConfirm"
                        @blur="handleInputConfirm"
                        >
                        </el-input>
                        <el-button v-else class="button-new-tag" size="small" @click="showInput">+ New Tag</el-button>
                    </el-form-item>



                    <el-form-item>
                        <el-button type="primary" @click="submit">确定</el-button>
                        <el-button style="margin-left: 10px;" type="danger" @click="dialogVisible = false">取消</el-button>
                    </el-form-item>

                </el-form>
            </div>

        </el-dialog>
    </div>
</template>

<script>
import axios from 'axios';

export default {
    data() {
        var validatePass = (rule, value, callback) => {
            if (value == '') {
                callback(new Error('请输入标题'));
            } else{
                return
            }
        };
        var sqltext = (rule, value, callback) => {
            if (value == '') {
                callback(new Error('请输入sql语句'));
            } else{
                return
            }
        };
       
        return {
            
            dialogVisible: false,   
            ruleForm: {
                title: '',
                sql:"",
            },
            rules: {
                title: [
                    { validator: validatePass, trigger: 'blur' }
                ],
                sql: [
                    { validator: sqltext, trigger: 'blur' }
                ],
            },

            // 标签相关
            dynamicTags: [],
            inputVisible: false,
            inputValue: '',
            // 数据相关
            datalist:[],
            value: "",
            tagvalue:"",
            taglist:[],
            loading:false,
            num:0,
            tag:"",
            // 分页相关
            page:1,
            pagesize:10,
            
        }
    },
    computed: {

    },
    watch: {

    },
    mounted() {
      this.loaddata();
    },
    methods: {
        handleClose(tag) {
        this.dynamicTags.splice(this.dynamicTags.indexOf(tag), 1);
        },

        showInput() {
            this.inputVisible = true;
            this.$nextTick(()=> {
            this.$refs.saveTagInput.$refs.input.focus();
            });
        },

        handleInputConfirm() {
            let inputValue = this.inputValue;
            if (inputValue) {
            this.dynamicTags.push(inputValue);
            }
            this.inputVisible = false;
            this.inputValue = '';
        },
        handleCurrentChange(val) {
            this.page = val;
            this.loaddata();
        },
        // 提交表单
        submit(){
            if(this.loading){
                return
            }
            this.loading = true;
            axios({
                method:"post",
                url:this.$wjh + this.$api.simple+"?mode=addsql",
                data:{
                    title:this.ruleForm.title,
                    sql:this.ruleForm.sql,
                    tag:this.dynamicTags,
                    username:JSON.parse(localStorage.getItem("userinfo")).user.name
                }
            }).then(res=>{
                if(res.data.code==200){
                    this.loading = false;
                    this.$message({
                        message: '添加成功',
                        type: 'success'
                    });
                    this.dialogVisible = false;
                    this.loaddata();
                }else{
                    this.loading = false;
                    this.$message({
                        message: '添加失败',
                        type: 'error'
                    });
                    this.dialogVisible = false;
                }
            })
        },

        // 加载数据
        loaddata(){
            if(this.loading){
                return
            }
            this.loading = true;
            axios({
                method:"get",
                url:this.$wjh + this.$api.simple+"?mode=getsql&value="+this.value+"&page="+this.page+"&tag="+this.tag,
            }).then(res=>{
                if(res.data.code==200){
                    this.datalist = res.data.data;
                    this.num = res.data.num;
                    this.loading = false;
                }else{
                    this.$message({
                        message: '获取数据失败',
                        type: 'error'
                    });
                    this.loading = false;
                }
            })

        },
        // 查询标签
        searchtag(){
            if(this.tagvalue==""){
                this.$message({
                    message: '请输入标签关键字',
                    type: 'error'
                });
                return
            }
            axios({
                method:"get",
                url:this.$wjh + this.$api.simple+"?mode=gettag&value="+this.tagvalue,
            }).then(res=>{
                if(res.data.code==200){
                    this.taglist = res.data.data;
                }else{
                    this.$message({
                        message: '获取数据失败',
                        type: 'error'
                    });
                }
            })
        },
        // 查询标题
        searchtitle(){
            if(this.value==""){
                this.$message({
                    message: '请输入标题关键字',
                    type: 'error'
                });
                return
            }else{
                this.loaddata()
            }
        },
        // 复制文本
        copyText(val){
            // console.log(index)
            var input = document.createElement('input');
            input.setAttribute('readonly', 'readonly');
            input.setAttribute('value', val);
            document.body.appendChild(input);
            input.select();
            if (document.execCommand('copy')) {
                document.execCommand('copy');
                this.$message({
                    message: '复制成功',
                    type: 'success'
                });
            }
            document.body.removeChild(input);
        },
        // 选中tag
        selecttag(val){
            this.tag = val
            this.loaddata()
        },

    }
};
</script>
<style scoped>
.main {
    width: 100%;
    min-height: 100vh;
    display: flex;
    align-items: top;
    justify-content: space-around;
}

.left {
    width: 70%;
}

.right {
    width: 25%;
}

.chead {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    font-size: 14px;
    color: #646262;
    gap: 5px;
}

.top{
    position: fixed;
    top:90px;
}
.el-tag + .el-tag {
    margin-left: 10px;
  }
  .button-new-tag {
    margin-left: 10px;
    height: 32px;
    line-height: 30px;
    padding-top: 0;
    padding-bottom: 0;
  }
  .input-new-tag {
    width: 90px;
    margin-left: 10px;
    vertical-align: bottom;
  }

.search {
    /* width: 100%; */
    display: flex;
    align-items: center;
    justify-content: center;
    gap: 5px;
}

.search .el-input_inner:focus {
    border-color: #ebfff0 !important;
    box-shadow: 0 0 0 2px rgba(0, 255, 0, 0.2) !important;
}

pre {
    margin: 0;
    padding-right: 0 !important;
    position: relative;
    width: 100%;
    display: flex;
    align-items: top;
}

.paste {
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    right: 1%;
    top: 15%;
    transition: all 0.3s ease;
    /* top:0 */
}
#tag{
    cursor: pointer;
}
.paste:hover{
    cursor: pointer;
    color: #518cc7;
    transition: all 0.3s ease;
}
code {
    width: 100%;
    padding: 13px 40px 13px 13px !important;
    word-wrap: break-word;
    white-space: pre-wrap;
}

.card {
    margin: 20px auto;
}
.tagbox{
    display: flex;
    align-content: center;
    justify-content: flex-start;
}
.tagdown{
    margin-top: 20px;
    gap:5px;
    flex-wrap: wrap;
}
.carddown {
    margin-top: 10px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    font-size: 12px;
    color: #787878;
}

.title {
    font-size: 14px;
    color: #646262;
    margin: 0;
    padding: 0;

}

.dash {
    width: 100%;
    height: 20px;
    border-bottom: 2px dashed #ebebeb;
}
</style>    